export function fiscalYear () {
  const today = new Date()
  const thisYear = today.getFullYear()
  const thisMonth = today.getMonth() + 1
  const fiscalYear = thisYear

  if (thisMonth < 4) {
    // eslint-disable-next-line
    fiscalYear = thisYear - 1
  }

  return fiscalYear
}
